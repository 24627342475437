import {IBasketData} from '../../definitions/basket';
import {
  BASKET_ADD_ERROR,
  BASKET_ADD_FINISHED,
  BASKET_ADD_STARTED,
  BASKET_REMOVE_ERROR,
  BASKET_REMOVE_FINISHED,
  BASKET_REMOVE_STARTED,
  CHECKOUT_ERROR,
  CHECKOUT_FINISHED,
  CHECKOUT_STARTED,
  FETCH_BASKET_ERROR,
  FETCH_BASKET_RECEIVE,
  FETCH_BASKET_STARTED,
  PAYMENT_INTENT_CREATED,
} from '../actions';
import {IAction} from '../store';

export type IBasketState = IBasketData;

export const initialState: IBasketData = {
  basketData: {
    detail: {
      addingItems: [],
      brassHardCopySheetMusic: [],
      brassMp3Albums: [],
      brassSheetMusic: [],
      removingItems: [],
      vocalHardCopySheetMusic: [],
      vocalMp3Albums: [],
      vocalSheetMusic: [],
      voucher: {
        code: '',
        discount: 0,
        errorDescription: '',
        successDescription: '',
      },
    },
    error: false,
    fetching: false,
  },
  checkoutData: {
    clientSecret: '',
    error: false,
    fetching: false,
  },
};

export default function basketReducer(
  state = initialState,
  action: IAction,
): IBasketData {
  switch (action.type) {
    case BASKET_ADD_STARTED: {
      const addingItems = state.basketData.detail.addingItems;
      const newId = `${action.data.category}${action.data.id}${
        action.data.hardCopy ? 'h' : ''
      }`;
      addingItems.push(newId);
      return {
        ...state,
        basketData: {
          ...state.basketData,
          detail: {
            ...state.basketData.detail,
            addingItems,
          },
        },
      };
    }

    case BASKET_ADD_FINISHED: {
      const newId = `${action.data.category}${action.data.id}${
        action.data.hardCopy ? 'h' : ''
      }`;
      const addingItems = state.basketData.detail.addingItems.filter((id) => {
        return id !== newId;
      });
      return {
        ...state,
        basketData: {
          ...state.basketData,
          detail: {
            ...state.basketData.detail,
            addingItems,
          },
        },
      };
    }

    case BASKET_REMOVE_STARTED: {
      const removingItems = state.basketData.detail.removingItems;
      const newId = `${action.data.category}${action.data.id}${
        action.data.hardCopy ? 'h' : ''
      }`;
      removingItems.push(newId);
      return {
        ...state,
        basketData: {
          ...state.basketData,
          detail: {
            ...state.basketData.detail,
            removingItems,
          },
        },
      };
    }

    case BASKET_REMOVE_FINISHED: {
      const newId = `${action.data.category}${action.data.id}${
        action.data.hardCopy ? 'h' : ''
      }`;
      const removingItems = state.basketData.detail.removingItems.filter(
        (id) => {
          return id !== newId;
        },
      );
      return {
        ...state,
        basketData: {
          ...state.basketData,
          detail: {
            ...state.basketData.detail,
            removingItems,
          },
        },
      };
    }

    case CHECKOUT_STARTED:
      return {
        ...state,
        checkoutData: {
          clientSecret: '',
          error: false,
          fetching: true,
        },
      };

    case CHECKOUT_ERROR:
      return {
        ...state,
        checkoutData: {
          clientSecret: '',
          error: true,
          fetching: false,
          message: action.message,
        },
      };

    case CHECKOUT_FINISHED:
      return {
        ...state,
        checkoutData: {
          clientSecret: '',
          error: false,
          fetching: false,
        },
      };

    case FETCH_BASKET_STARTED:
      return {
        ...state,
        basketData: {
          ...state.basketData,
          error: false,
          fetching: true,
        },
      };

    case FETCH_BASKET_RECEIVE:
      return {
        ...state,
        basketData: {
          ...state.basketData,
          detail: {
            ...state.basketData.detail,
            ...action.data,
          },
          fetching: false,
        },
      };

    case BASKET_ADD_ERROR:
    case BASKET_REMOVE_ERROR:
    case FETCH_BASKET_ERROR:
      return {
        ...state,
        basketData: {
          ...state.basketData,
          error: true,
          fetching: false,
        },
      };

    case PAYMENT_INTENT_CREATED:
      return {
        ...state,
        checkoutData: {
          clientSecret: action.data.clientSecret,
          error: false,
          fetching: false,
        },
      };

    default:
      return state;
  }
}
